import React, { useState, useEffect } from "react";
import mqtt, { MqttClient } from "mqtt";
import "./App.css";
import Icon from "./components/Icon";

// const test_text =
//   "tag-1397234987323-sword-Eddie-Hughes-hit-readerID-9423702352345-arm_L-Erik-Child.";
const App: React.FC = () => {
  const [connectionStatus, setConnectionStatus] =
    useState<string>("Disconnected");
  const [mqttClient, setMqttClient] = useState<MqttClient | null>(null);
  const [healthPercentage, setHealthPercentage] = useState(100);
  const [manaPercentage, setManaPercentage] = useState(100);
  const [signal, setSignal] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    // Create a client with a different variable name to avoid confusion
    const client = mqtt.connect(
      `wss://${process.env.REACT_APP_MQTT_HOST}:${process.env.REACT_APP_MQTT_PORT}/mqtt`,
      {
        username: process.env.REACT_APP_MQTT_USERNAME,
        password: process.env.REACT_APP_MQTT_PASSWORD,
      }
    );

    client.on("connect", () => {
      console.log("Connected to MQTT broker");
      setConnectionStatus("Connected");

      // Subscribe to the happy topic when connected
      client.subscribe("happy", (err) => {
        if (err) {
          console.error("Error subscribing to happy topic:", err);
        } else {
          console.log("Successfully subscribed to happy topic");
        }
      });
    });

    client.on("error", (err) => {
      console.error("MQTT connection error:", err);
      setConnectionStatus("Disconnected");
    });

client.on("message", (topic, message) => {
  console.log(`Received message on topic ${topic}: ${message.toString()}`);
  
  try {
    // Only process messages from the happy topic
    if (topic === "happy") {
      const payload = message.toString();
      const dataArray = payload.split("-");
      
      // Make sure we have enough elements in the array
      if (dataArray.length >= 9) {
        if (healthPercentage <= 0) {
          // If health is already 0 and we receive a new hit, reset to 100
          setHealthPercentage(100);
        } else {
          // Normal case - reduce health based on hit location
          updatedHealthPercentage(dataArray[8]);
          updateSignal(dataArray[8]);
        }
      } else {
        console.warn("Received message format is incorrect:", payload);
      }
    }
  } catch (error) {
    console.error("Error processing message:", error);
  }
});

    setMqttClient(client);

    return () => {
      console.log("Disconnecting MQTT...");
      client.end();
    };
  }, []);

  const updatedHealthPercentage = (type: string) => {
    let reduction = 0;

    switch (type) {
      case "Arm_L":
      case "Arm_R":
      case "Leg_L":
      case "Leg_R":
        reduction = 1;
        break;
      case "Back":
        reduction = 2;
        break;
      case "Chest":
        reduction = 3;
        break;
      default:
        return;
    }

    setHealthPercentage((prev) => {
      const newHealth = Math.max(0, prev - reduction);
      // If health reaches 0, reset to 100 on the next update
      if (newHealth === 0) {
        return 100;
      }
      return newHealth;
    });
  };

  const updateSignal = (type: string) => {
    switch (type) {
      case "Arm_L":
        const newSignalL = [...signal];
        newSignalL[0] = true;
        setSignal(newSignalL);
        break;
      case "Arm_R":
        const newSignalR = [...signal];
        newSignalR[1] = true;
        setSignal(newSignalR);
        break;
      case "Leg_R":
        const newSignalLegL = [...signal];
        newSignalLegL[2] = true;
        setSignal(newSignalLegL);
        break;
      case "Leg_L":
        const newSignalLegR = [...signal];
        newSignalLegR[3] = true;
        setSignal(newSignalLegR);
        break;
      case "Chest":
        const newSignalFront = [...signal];
        newSignalFront[4] = true;
        setSignal(newSignalFront);
        break;
      case "Back":
        const newSignalBack = [...signal];
        newSignalBack[5] = true;
        setSignal(newSignalBack);
        break;
      default:
        break;
    }
  };

  // Function to update mana percentage (similar to health)
  const updateManaPercentage = (amount: number) => {
    setManaPercentage((prev) => Math.max(0, prev + amount));
  };

  return (
    <div className="container">
      <div className="healthBar">
        <div className="bar-container">
          <div className="bar-label">Health</div>
          <div className="bar health-bar">
            <div
              className="bar-fill"
              style={{ width: `${healthPercentage}%` }}
            ></div>
            <span className="percentage-text">{healthPercentage}%</span>
          </div>
        </div>
        <div className="bar-container">
          <div className="bar-label">Mana</div>
          <div className="bar mana-bar">
            <div
              className="bar-fill"
              style={{ width: `${manaPercentage}%` }}
            ></div>
            <span className="percentage-text">{manaPercentage}%</span>
          </div>
        </div>
      </div>

      <div className="content">
        {/* Connection Status */}
        {connectionStatus === "Disconnected" && (
          <div className="status-indicator">
            <span style={{ color: "white" }}>Connection Status: </span>
            <span className="status">{connectionStatus}</span>
          </div>
        )}
      </div>
      <div className="icon-container">
        <Icon signal={signal[0]} imageUrl="./assets/arm.png" name="armLeft" />
        <Icon signal={signal[1]} imageUrl="./assets/arm.png" name="armRight" />
        <Icon signal={signal[2]} imageUrl="./assets/leg.png" name="legLeft" />
        <Icon signal={signal[3]} imageUrl="./assets/leg.png" name="legRight" />
        <Icon signal={signal[4]} imageUrl="./assets/front.png" name="front" />
        <Icon signal={signal[5]} imageUrl="./assets/back.png" name="back" />
      </div>
    </div>
  );
};

export default App;
