import React from "react";
import "../App.css";

interface IconProps {
  signal: boolean;
  name: string;
  imageUrl: string;
}
const Icon: React.FC<IconProps> = ({ signal, name, imageUrl }) => {
  const isRotated = name === "armRight" || name === "legRight";
     
  return (
    <div className="icon">
          <div className={`signal ${signal ? "signal-active" : ""}`}></div>
      <div className={`icon-image-container ${isRotated ? "rotated" : ""}`}>
        <img src={imageUrl} alt={name} className="icon-image" />
      </div>
    </div>
  );
};

export default Icon;
